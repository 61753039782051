@import '~app/mixins';

.panel {
  padding-left: 5px;
  padding-right: 5px;
}

.searchItems {
  $offset: 4px;

  display: flex;
  margin: -$offset 0 20px;

  > a,
  > div {
    margin: 0 $offset;
    width: 116px;
  }

  button {
    width: 100%;
  }
}

.title {
  color: $color-blue-dark;
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 5px;
}

.titleContainer {
  position: relative;

  .selected {
    position: absolute;
    top: 20px;
    margin-left: 7px;
    font-size: 13px;
    font-style: italic;
    color: $color-blue-dark;
    white-space: nowrap;
  }
}

.content {
  display: flex;
  flex-wrap: nowrap;
}

.left,
.right {
  position: relative;
}

.left {
  width: 45%;
}

.leftSearch {
  width: 240px;
}

.rightSearch {
  width: 325px;
}

.right {
  padding: 0 0 0 15px;
  width: 55%;

  .fix {
    // 30px is 2 padding by 15px
    width: calc(100% - 30px);
  }
}

.range {
  display: flex;
  align-items: center;
  width: 190px;

  .divider {
    color: $color-grey-1;
    font-size: 12px;
    line-height: 18px;
    padding: 0 10px;
  }
}

.flexRow {
  display: flex;
  justify-content: space-between;
}

.justifyContent {
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 8px;
  }
}

.flexRow,
.range {
  $width: 90px;

  input[type="text"] {
    width: $width;
    border-color: $color-blue-darkest;
    color: $color-grey-1;

    @include placeholder($color-grey-1);
  }

  label {
    white-space: nowrap;
    width: $width;
  }
}

.radioGroup {
  width: 230px;
}

.mapWrap {
  width: 100%;
  height: 405px;
  border: 1px solid $color-grey;
  position: relative;
}

.button {
  font-weight: normal;
  position: relative;
}

.tableComparableProperties {
  margin-top: 10px;
  overflow-x: hidden;
}

.tableComparablePropertiesExtraSpace {
  margin-top: 16px;
}

.buttonsExpanded {
  text-align: center;
  padding: 15px 0;
}

.buttonExpanded {
  margin: 0 5px;
}

.contentWrapper {
  height: calc(100vh - 570px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 1px;
  position: relative;
}

.fix {
  width: 100%;
  position: absolute;
}

.relative {
  position: relative;
}
